import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  AfterViewInit,
  ViewEncapsulation
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfigService } from 'src/app/config/config.service';
import { AuthService } from 'src/app/core/service/auth.service';
import { RightSidebarService } from 'src/app/core/service/rightsidebar.service';
import { LanguageService } from 'src/app/core/service/language.service';
import { TaskRecipientService } from 'src/app/core/service/task-recipient.service';
import { TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery';
import { Company } from 'src/app/core/models/company';
import { CompanyService } from 'src/app/core/service/company.service';
import { ApiObjectData } from 'src/app/core/models/api-object-data';
import { BaseComponent } from 'src/app/components/base/base.component';
import { takeUntil } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationResult, NotificationCountResult } from '../../core/models/notification-net';
import { NotificationNetService } from '../../core/service/notification-net.service';
import { ModalService } from '../../core/service/modal.service';
import { User } from 'src/app/core/models/user';
import { BehaviorSubject } from 'rxjs';

const document: any = window.document;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent extends BaseComponent implements AfterViewInit, OnInit {
  public config: any = {};
  isNavbarCollapsed = true;
  isNavbarShow: boolean;
  flagvalue;
  countryName;
  langStoreValue: string;
  defaultFlag: string;
  numberOfNotifications = 0;
  companies: Company[];
  company: Company;
  logoUrl: any;
  inspectionDetails: any;
  base64Image: any;
  currentUserSubject: BehaviorSubject<User>;
  messages: Array<NotificationResult>;
  notification: NotificationCountResult;
  errorMessage = '';
  showPack = false;
  days: number;
  packend = false;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private dataService: RightSidebarService,
    private configService: ConfigService,
    private authService: AuthService,
    private taskRecipientService: TaskRecipientService,
    private router: Router,
    private route: ActivatedRoute,
    public languageService: LanguageService,
    private translate: TranslateService,
    private companyService: CompanyService,
    private domSanitizer: DomSanitizer,
    private notificationService: NotificationNetService,
    private modalService: ModalService

  ) {
    super();
    translate.setDefaultLang('ar');

  }
  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' }
  ];
  isExpanded = false;

  refreshNotification() {
    this.ngOnInit();
  }

  ngOnInit() {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('user'))
    );
    this.days = Number(localStorage.getItem('packdat'));
    if (this.days < 30) {
      this.packend = true;
    }
    if (this.days < 0) {
      this.days = 0;
      this.packend = true;
    }
    if (this.currentUserSubject.value.isRegister === true) { this.showPack = true; }
    else { this.showPack = false; }
    // console.log(this.showPack);
    this.companyService.gets().pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (apidata: ApiObjectData) => {
        this.companies = apidata.returnData as Company[];
        this.company = this.companies[0];
        // console.log(this.company);
        this.logoUrl = this.inspectionDetails?.reportImage;
        this.base64Image = 'data:image/png;base64,' + this.company?.logo;
      }
    );

    // this.getNotificationCount();
    // const connection = new signalR.HubConnectionBuilder()
    //   .configureLogging(signalR.LogLevel.Information)
    //   .withUrl(environment.baseUrl + 'notify')
    //   .build();

    // connection.start().then(function () {
    //   console.log('SignalR Connected!');
    // }).catch(function (err) {
    //   return console.error(err.toString());
    // });

    // connection.on("BroadcastMessage", () => {
    //   this.getNotificationCount();
    // });

    // this.getNotificationMessage()

    this.loadData();

    // this.config = this.configService.configData;

    // this.langStoreValue = localStorage.getItem('lang');
    // const val = this.listLang.filter((x) => x.lang === this.langStoreValue);
    // this.countryName = val.map((element) => element.text);
    // if (val.length === 0) {
    //   if (this.flagvalue === undefined) {
    //     this.defaultFlag = 'assets/images/flags/us.jpg';
    //   }
    // } else {
    //   this.flagvalue = val.map((element) => element.flag);
    // }
    document.getElementById('language').innerText = (localStorage.getItem('dir') === 'rtl' ? 'English' : 'العربية');

  }
  getNotificationCount() {
    this.notificationService.getNotificationCount().subscribe(
      notification => {
        this.notification = notification;
      },
      error => this.errorMessage = (error as any)
    );
  }
  getNotificationMessage() {
    this.notificationService.getNotificationMessage().subscribe(
      messages => {
        this.messages = messages;
      },
      error => this.errorMessage = (error as any)
    );
  }
  deleteNotifications(): void {
    if (confirm(`Are you sure want to delete all notifications?`)) {
      this.notificationService.deleteNotifications()
        .subscribe(
          () => {
            // this.closeModal();
            $('.dropdown-notifications').removeClass('show');
            $('.dropdown-menu').removeClass('show');
            $('.collapse').removeClass('show').attr('ng-reflect-collapsed', 'true');
            $('.dropdown-toggle').attr('aria-expanded', 'false');
            $('.notification-item').addClass('vanesh');
          },
          (error: any) => this.errorMessage = (error as any)
        );
    }
  }

  loadData() {
    // this.taskRecipientService.getsNotifications().subscribe(data => {
    //   this.notifications = data.returnData
    //   this.numberOfNotifications = this.notifications.length
    // }
    // )
  }
  ViewRequest(url) {

    $('.dropdown-notifications').removeClass('show');
    $('.dropdown-menu').removeClass('show');
    $('.collapse').removeClass('show').attr('ng-reflect-collapsed', 'true');
    $('.dropdown-toggle').attr('aria-expanded', 'false');

    this.router.navigate([url]);
  }
  closeDropDown(url) {

    $('.dropdown').removeClass('show');
    $('.dropdown-menu').removeClass('show');
    $('.collapse').removeClass('show').attr('ng-reflect-collapsed', 'true');
    $('.dropdown-toggle').attr('aria-expanded', 'false');

    this.router.navigate([url]);
  }
  changeLang() {
    const lang = this.languageService.getLanguage();
    if (lang === 'English') {
      this.languageService.setLayout('ltr', 'rtl');
      this.languageService.setLanguage('en');
      // document.getElementById('language').innerText = 'العربية';
    } else if (lang === 'العربية') {
      this.languageService.setLayout('rtl', 'ltr');
      this.languageService.setLanguage('ar');
      // document.getElementById('language').innerText = 'English';
    }
    parent.document.location.reload();
  }

  ngAfterViewInit() {
    // set theme on startup
    // if (localStorage.getItem('theme')) {
    //   this.renderer.removeClass(this.document.body, this.config.layout.variant);
    //   this.renderer.addClass(this.document.body, localStorage.getItem('theme'));
    // } else {
    //   this.renderer.addClass(this.document.body, this.config.layout.variant);
    // }

    // if (localStorage.getItem('menuOption')) {
    //   this.renderer.addClass(
    //     this.document.body,
    //     localStorage.getItem('menuOption')
    //   );
    // } else {
    //   this.renderer.addClass(
    //     this.document.body,
    //     'menu_' + this.config.layout.sidebar.backgroundColor
    //   );
    // }

    // if (localStorage.getItem('choose_logoheader')) {
    //   this.renderer.addClass(
    //     this.document.body,
    //     localStorage.getItem('choose_logoheader')
    //   );
    // } else {
    //   this.renderer.addClass(
    //     this.document.body,
    //     'logo-' + this.config.layout.logo_bg_color
    //   );
    // }

    // if (localStorage.getItem('sidebar_status')) {
    //   if (localStorage.getItem('sidebar_status') === 'close') {
    //     this.renderer.addClass(this.document.body, 'side-closed');
    //     this.renderer.addClass(this.document.body, 'submenu-closed');
    //   } else {
    //     this.renderer.removeClass(this.document.body, 'side-closed');
    //     this.renderer.removeClass(this.document.body, 'submenu-closed');
    //   }
    // } else {
    //   if (this.config.layout.sidebar.collapsed === true) {
    //     this.renderer.addClass(this.document.body, 'side-closed');
    //     this.renderer.addClass(this.document.body, 'submenu-closed');
    //   }
    // }
  }

  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.langStoreValue = lang;
    // this.languageService.setLanguage(lang);
  }
  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }
  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains('side-closed');
    const bigLogo = this.document.getElementById('big-Logo');
    const smallLogo = this.document.getElementById('small-Logo');
    if (hasClass) {
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');


      this.renderer.removeClass(bigLogo, 'hide-logo');
      this.renderer.removeClass(smallLogo, 'show-logo');
      this.renderer.addClass(bigLogo, 'show-logo');
      this.renderer.addClass(smallLogo, 'hide-logo');
    } else {
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
      this.renderer.removeClass(bigLogo, 'show-logo');
      this.renderer.removeClass(smallLogo, 'hide-logo');
      this.renderer.addClass(bigLogo, 'hide-logo');
      this.renderer.addClass(smallLogo, 'show-logo');
    }
  }
  public toggleRightSidebar(): void {
    this.dataService.changeMsg(
      (this.dataService.currentStatus._isScalar = !this.dataService
        .currentStatus._isScalar)
    );
  }
  logout() {
    this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(['/authentication/signin']);
      }
    });
  }

}
