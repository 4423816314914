import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiObjectData } from '../models/api-object-data';
import { Role } from '../models/role';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class TaskRecipientService {

    controller = 'taskrecipient';

    constructor(private base: BaseService) { }


    getsNotifications(): Observable<ApiObjectData> {
        return this.base.gets(this.controller + '/GetByUser');
    }
}
