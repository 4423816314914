import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiObjectData } from '../models/api-object-data';
import { Company } from '../models/company';
import { BaseService } from './base.service';
import { CompanyApproval } from '../models/company-approval'; 

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    controller = 'Company';

    constructor(private base: BaseService) { }

    get(id): Observable<ApiObjectData> {
        return this.base.get(id, this.controller);
    }

    gets(): Observable<ApiObjectData> {
        return this.base.gets(this.controller);
    }

    getByApprovalId(id): Observable<ApiObjectData> {
        return this.base.gets(this.controller + '/GetByApprovalId/' + id);
    }

    getByUser() {
        return this.base.gets( this.controller + '/GetByUser');
    }

    removeCompanyApprove(id: number) {
        return this.base.deleteApproval(this.controller, id);
    }

    saveCompanyApproval(obj: CompanyApproval) {
        return this.base.save(this.controller + '/SaveCompanyApproval', obj);
    }

    getInvoiceTypes(){
        return this.base.gets( this.controller + '/GetInvoiceTypes');
    }

    save(obj: Company) {
        return this.base.save(this.controller, obj);
    }

    remove(id: number) {
        // console.log(id)
        return this.base.remove(this.controller, id);
    }
}
