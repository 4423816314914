<nav #navbar class="navbar">
  <div class="container-fluid">
    <div class="navbar-header d-flex align-items-center flex-row-reverse" style="padding: 0; border-right:unset">
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <!-- <a class="navbar-brand" routerLink="/page/home" style="margin:auto; height: 60px;">
        <img src="assets/images/4signing3.png" alt="" />
        <span class="logo-name">{{"SystemTitle" | translate}}</span>
      </a> -->
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <a class="navbar-brand show-logo" id="big-Logo" routerLink="page/home" style="height: 60px;margin: unset;">
        <img src="assets/images/Fatoora2.png" alt="" style="float: none; margin-top: 5px;" />
        <!-- <span class="logo-name my-auto">{{"SystemTitle" | translate}}</span> -->
      </a>
      <a class="navbar-brand hide-logo" id="small-Logo" routerLink="page/home" style="height: 60px;margin: unset;">
        <img src="assets/images/Fatoora1.png" alt="" />
        <span class="logo-name my-auto">{{"SystemTitle" | translate}}</span>
      </a>
    </div>
    <div class="collapse navbar-collapse" id="collapse " [ngbCollapse]="isNavbarCollapsed">
      <ul class="pull-left collapse-menu-icon">
        <li>
          <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <i class="nav-hdr-btn ti-align-left"></i>
          </button>

        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <!-- Full Screen Button -->
        <li class="fullscreen">
          <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">
            <i class="nav-hdr-btn ti-fullscreen"></i>
          </button>
        </li>
        <li class="nav-item">
          <a class="lang">
            <button (click)="changeLang()" id="language">English</button>
          </a>
        </li>
        <li class="nav-item dropdown-notifications" ngbDropdown id="dropdown-notifications">
          <button mat-icon-button ngbDropdownToggle class="nav-notification-icons">
            <i class="nav-hdr-btn ti-bell" (click)="refreshNotification()"></i>
            <!-- <span class="notify"></span>
            <span class="heartbeat"></span> -->
            <span class="notification" *ngIf="notification && notification?.count>0">{{notification?.count}}</span>
            <!-- <span class="notification over_5" *ngIf="6 <= notification?.count">5 +</span> -->
          </button>
          <div ngbDropdownMenu id="notificationdropdown" class="notification-dropdown pullDown">
            <div class="removeBtn" style="text-align: center;">
              <button mat-raised-button type="button" class="btn btn-success" (click)="deleteNotifications()"
                [disabled]="notification?.count==0"> حذف التنبيهات </button>
            </div>
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 250px;" [perfectScrollbar]>
              <ul class="menu">
                <li *ngFor="let msg of messages" [ngSwitch]="msg.tranType" style="border-bottom: none;">
                  <a class="notification-item">
                    <div class="notification-content d-flex justify-content-between align-items-start">
                      <div class="notification-detalis">
                        <p *ngSwitchCase="'Add'" class="notification-name m-0"> تم إضافة الموظف <span
                            style="color: #002447;">'{{msg.employeeName}}'</span></p>
                        <p *ngSwitchCase="'Edit'" class="notification-name m-0" style="color: #17a2b8;">تم تعديل الموظف
                          <span style="color: #002447;">'{{msg.employeeName}}'</span></p>
                        <p *ngSwitchCase="'Delete'" class="notification-name m-0" style="color: #c82333;">تم حذف الموظف
                          <span style="color: #002447;">'{{msg.employeeName}}'</span></p>
                        <!-- <p class="notification-describtion">{{notification.describtion}}</p> -->
                      </div>
                      <!-- <span class="notification-time d-flex align-items-center">
                        <i class="material-icons mx-2">access_time</i> {{notification.hours}} {{'hour' | translate}}
                      </span> -->
                    </div>
                  </a>
                </li>
                <li *ngIf="packend" style="text-align: center;">
                  <a class="notification">
                    <div class="notification-content d-flex justify-content-between align-items-start">
                      <div class="notification-detalis">
                        <p class="notification-name m-0"><span style="color: #002447; text-align: center;">باقى اقل من
                            شهر لانتهاء الباقة</span></p>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <a *ngIf="notification?.count==0" class="text-center d-block py-1" style="color: var(--subColor);">
              لا يوجد تنبيهات</a>
            <a class="text-center d-block border-top py-1" (click)="closeDropDown('/page/view-all-notifications')"
              style="color: var(--subColor);">
              {{'readAllNotifications' | translate}}</a>
          </div>
        </li>
        <!-- #END# Notifications-->
        <li class="nav-item user_profile" ngbDropdown>
          <a href="#" onClick="return false;" ngbDropdownToggle role="button" class="nav-notification-icons pt-0">
            <img src="assets/images/user.jpg" class="rounded-circle" width="32" height="32" alt="User">
          </a>
          <div ngbDropdownMenu class="notification-dropdown pullDown ">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li (click)="closeDropDown('/page/Profile')">
                      <a href="#" onClick="return false;" style="font-size: larger">
                        <mat-icon aria-hidden="false" class="mr-2">person</mat-icon>{{'Profile' | translate}}
                      </a>
                    </li>
                    <li (click)="closeDropDown('/page/changePassword')">
                      <a href="#" onClick="return false;" style="font-size: larger">
                        <mat-icon aria-hidden="false" class="mr-2 ml-2">password</mat-icon>
                        {{'ChangePassword'| translate}}
                      </a>
                    </li>
                    <li *ngIf="this.showPack===true" (click)="closeDropDown('/page/myPackages')">
                      <a href="#" onClick="return false;" style="font-size: larger">
                        <mat-icon aria-hidden="false" class="mr-2 ml-2">card_membership</mat-icon>
                        {{"MyPackages" | translate}}
                      </a>
                    </li>
                    <!-- <li *ngIf="this.showPack===true" (click)="closeDropDown('/page/myPayments')">
                      <a href="#" onClick="return false;" style="font-size: larger">
                        <mat-icon aria-hidden="false" class="mr-2 ml-2">production_quantity_limits</mat-icon>
                        {{"MyPayments" | translate}}
                      </a>
                    </li> -->
                    <li>
                      <a href="javascript:void(0);" (click)="logout()" style="font-size: larger">
                        <mat-icon aria-hidden="false" class="mr-2">power_settings_new</mat-icon>{{'logout' | translate}}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <!-- #END# Tasks -->
        <!-- <li class="pull-right">
          <button mat-icon-button (click)="toggleRightSidebar()" class="nav-notification-icons">
            <i class="nav-hdr-btn ti-layout-grid2" id="settingBtn"></i>
          </button>
        </li> -->
      </ul>

      <ul class="nav navbar-nav navbar-center" *ngIf="this.days<=15">
        <li>
          <a>
            <img src="../../../assets/images/icons/warning-blink.gif" alt="" width="20px" style="margin: 0 5px;">
            {{'remain' | translate}} {{this.days}} {{'days' | translate}}</a>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-center" style="padding: 0; float: right; margin: 0;">
        <li><a class="companyName" routerLink="/page/home"><span
              style="font-size: large;font-weight: 100; margin-left: 10px; margin-right: 10px;">{{this.company?.nameAr}}</span></a>
        </li>
        <li>
          <div class="companyLogoContainer" style="width: 55px;" *ngIf="this.company?.logo">
            <img [src]="base64Image"
              onerror="this.onerror=null;this.src='../../../../../assets/images/placeholder-image.png';">
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>>