// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //baseUrl: 'http://192.168.1.100:2051',
  //domainUrl: '192.168.1.100:2051',
   baseUrl: 'http://localhost:2041',
   domainUrl: 'localhost:2041',
  // baseUrl: 'http://matrixzone.ddns.net:2050',
  // domainUrl: 'matrixzone.ddns.net:2050',

  // baseUrl: 'http://api.test.fatora.aldawal.com',
  // domainUrl: 'api.test.fatora.aldawal.com',
  payUrl: 'https://pay.aldawal.com',
  siteUrl: 'http://localhost:50593'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
