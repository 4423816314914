import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { locale, loadMessages } from 'devextreme/localization';
import messages from '../../../assets/data/devextreme/locale.json';
import { Observable } from 'rxjs';
import { ApiObjectData } from '../models/api-object-data';
import { Language } from '../models/language';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {

  controller = 'language';

  constructor(
    private translate: TranslateService,
    private baseService: BaseService
  ) { }

  get(id): Observable<ApiObjectData> {
    return this.baseService.get(id, this.controller);
  }

  gets(): Observable<ApiObjectData> {
    return this.baseService.gets(this.controller);
  }

  save(obj: Language) {
    return this.baseService.save(this.controller, obj);
  }

  remove(id: number) {
    // console.log(id);
    return this.baseService.remove(this.controller, id);
  }
  // get_language
  getLanguage() {
    let lang = document.getElementById('language').innerText;
    return lang;
  }
  // set_language
  setLanguage(lang) {
    //loadMessages(messages);
    this.baseService.lang = lang;
    localStorage.setItem('lang', this.baseService.lang);
    //this.translate.use(this.baseService.lang);
    //locale(this.baseService.lang);
  }
  // set_layout
  setLayout(newLayout, oldLayout) {
    this.baseService.dir = newLayout;
    // this.baseService.isRTL =  this.baseService.dir === 'rtl' ? true : false;
    localStorage.setItem('dir', this.baseService.dir);
    //document.getElementsByTagName('html')[0].setAttribute('dir', newLayout);
    //document.getElementsByTagName('body')[0].classList.remove(oldLayout);
    //document.getElementsByTagName('body')[0].classList.add(newLayout);
  }
}
